import axios from "axios";

// NOTE: https://stackoverflow.com/a/59588664
// Will need to change this if not running on an emulator
// export default axios.create({
//     baseURL: 'http://10.0.2.2:5000/',
// });

// This now works on emulator
export default axios.create({
	baseURL: "https://expresso-api-g4ge6jtwjq-uk.a.run.app/api/v1/",
});
