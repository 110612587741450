import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import React from "react";

const Measurement = ({ measurementType, measurementValue, measurementUnit }) => {

	return (
		<Grid
			item
			container
			xs
			justifyContent="center"
			alignItems="center"
			direction="column"
			sx={{
				borderLeft: "6px solid #31D1C5",
				width: "95%",
				backgroundColor: "#273238",
				borderRadius: "6px",
				textAlign: 'center',
				mb: {sm: 1, md: 1.5, xl: 2},
				py: {sm: 0.5, md: 1, xl: 2}
			}}
		>
			<Typography variant="h6" sx={{px: {sm: 1, md: 0}, fontSize: {sm: '1.1rem', md: '1.17rem', lg: '1.3rem', xl: '1.4rem'}}}>{measurementType}</Typography>
			{measurementValue === 0
				? <Typography variant="h4" sx={{fontWeight: 'bold', fontSize: {sm: '1.1rem', md:'1.4rem', lg:'1.6rem', xl: '2rem'}}}>--</Typography>
				: <Typography variant="h4" sx={{fontWeight: 'bold', fontSize: {sm: '1.1rem', md:'1.4rem', lg:'1.6rem', xl: '2rem'}}}>{measurementValue} {measurementUnit}</Typography>
			}
			
		</Grid>
	);
};

export default Measurement;
