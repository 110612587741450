import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import styles from "../app.module.css";
import axios from "../api/axios";
import Lobby from "../components/Lobby";
import Room from "../features/room/Room";
import {useSelector, useDispatch} from 'react-redux'
import {
	setRoomName,
	setAccessToken,
} from '../features/room/roomSlice'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { connect } = require('twilio-video');

export default function Main() {
    const roomName = useSelector((state) => state.room.roomName)
	const accessToken = useSelector((state) => state.room.accessToken)
	const [room, setRoom] = useState(null)

	const dispatch = useDispatch()

	const [username, setUsername] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	let params = useParams();
	useEffect(() => {
		dispatch(setRoomName(params.room_name))
	}, [roomName, dispatch])

	const handleUsernameChange = useCallback((event) => {
		setUsername(event.target.value);
	}, []);

	function handleLeaveRoom() {
		setUsername("")
		setRoom(null)
	}

	const getToken = useCallback(
		async (event) => {
			event.preventDefault();
			setIsLoading(true)
			axios
				.post("twilio/" + roomName, {
					username: username,
				})
				.then((response) => {
					console.log(response.data);
					dispatch(setAccessToken(response.data.access_token))
				})
				.catch(function (error) {
					console.log(error);
					notifyError(error)
					setIsLoading(false)
				});
		},
		[roomName, username, accessToken, dispatch]
	);

	useEffect(() => {
		const joinRoom = async() => {
			const room = await connect(accessToken, {
				name: roomName
			})
			setIsLoading(false)
			setRoom(room)
			console.log(room)
		}
		if (accessToken !== '') {
			joinRoom()
		}
	}, [accessToken])
	

	function notifyError(error){
		toast.error(error, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
		});
	}

	return (
		<Box className={styles.container}>
			{room === null ? (
				<Lobby
					username={username}
					handleUsernameChange={handleUsernameChange}
					handleSubmit={getToken}
					isLoading={isLoading}
				/>
			) : (
				<Room
					room={room}
					leaveRoom={handleLeaveRoom} 
				/>
			)}
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</Box>
	);
}
