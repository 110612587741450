import React from "react";
import Main from "./screens/Main";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import {Provider} from 'react-redux'
import store from './app/store'

function App() {
	return (
		<React.Fragment>
			<Provider store={store}>
				<CssBaseline />
				<Routes>
					<Route path=":room_name">
						<Route path="join" element={<Main />} />
					</Route>
				</Routes>
			</Provider>
			{/* <Toast config={toastConfig} /> */}
		</React.Fragment>
	);
}

export default App;
