import React from 'react';
import {useSelector} from 'react-redux'
import { Divider } from "@mui/material";
import Measurement from "../features/measurement/Measurement";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';


const MeasurementSidebar = () => {
    const temperature = useSelector((state) => state.measurement.temperature)
	const weight = useSelector((state) => state.measurement.weight)
	const bp = useSelector((state) => state.measurement.bp)
	const bg = useSelector((state) => state.measurement.bg)
	const oxygen = useSelector((state) => state.measurement.oxygen)
    const heartRate = useSelector((state) => state.measurement.heartRate)

    return (
        <Paper 
            sx={{
                borderRadius: "6px", 
                p: {sm: 1, md: 1.5, xl: 2},
                margin: 'auto',
                maxWidth: '320',
                background: '#1E292F',
            }}
        >
            <Grid 
                container
            >
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" alignItems="center">
                        <Grid 
                            item
                            xs
                            container
                            alignItems="center"
                            direction="column"
                            sx={{
                                width: 1, 
                                pb: {sm: 1.5, md: 1.5, lg: 2, xl: 3}
                            }}
                        >
                            <Typography variant="h5" sx={{
                                pb: {sm: 0.5, md: 0.5, lg: 1.3, xl: 2}, 
                                mt: {sm: 0.1, md: 0.3, lg: 0.5, xl: 1},
                                fontWeight: 'bold'
                                }}>
                                    Measurements
                            </Typography> 
                            <Divider sx={{ display: "flex", width: 0.95}} />
                        </Grid>
                        <Measurement
                            measurementType="Temperature"
                            measurementValue={temperature}
                            measurementUnit="°F"
                        />
                        <Measurement
                            measurementType="Weight"
                            measurementValue={weight}
                            measurementUnit="lbs"
                        />
                        <Measurement
                            measurementType="Blood Pressure"
                            measurementValue={bp}
                            measurementUnit="mm Hg"
                        />
                        <Measurement
                            measurementType="Blood Glucose"
                            measurementValue={bg}
                            measurementUnit="mm/dL"
                        />
                        <Measurement
                            measurementType="Oxygen Saturation"
                            measurementValue={oxygen}
                            measurementUnit="%"
                        />
                        <Measurement
                            measurementType="Heart Rate"
                            measurementValue={heartRate}
                            measurementUnit="BPM"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default MeasurementSidebar;