import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    accessToken: '',
    roomName: '',
    status: '',
}

export const roomSlice = createSlice({
    name: 'room',
    initialState: initialState,
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
        setRoomName: (state, action) => {
            state.roomName = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        resetRoom(state, action) {
            return initialState
        }
    }
})

// Action creators are generated for each case reducer function
export const 
    {
        setAccessToken, 
        setRoomName, 
        setStatus, 
        resetRoom
    } = roomSlice.actions

export default roomSlice.reducer