import React, {useCallback, useState} from "react";
import axios from "../../api/axios";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useSelector} from 'react-redux'

const InviteModal = ({open, handleClose}) => {
    const roomName = useSelector((state) => state.room.roomName)
    const [email, setEmail] = useState();

    const handleEmailChange = useCallback((event) => {
		setEmail(event.target.value);
	}, []);

    const handleSubmit = useCallback(
		async (event) => {
			event.preventDefault();
			axios
				.post("twilio/" + roomName + "/invite", {
					email: email,
				})
				.then((response) => {
					console.log(response.data);
					handleClose();
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		[roomName, email]
	);

    const CustomDialogTitle = (props) => {
		const { children, onClose, ...other } = props;

		return (
			<DialogTitle
				textAlign="center"
				style={{
					color: "white",
					fontSize: 24,
					marginTop: 8,
				}}
				sx={{ m: 0, p: 2 }}
				{...other}
			>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
		);
	};

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"md"}
            PaperProps={{elevation: 1, style: {backgroundColor: '#121E24'}}}
        >
            <CustomDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Invite Guests to Your Live Appointment
            </CustomDialogTitle>
            <DialogContent>
                <Divider variant="middle" />
                <DialogContentText
                    textAlign="center"
                    style={{
                        color: "white",
                        fontSize: 20,
                        marginBottom: 2,
                        marginTop: 10,
                    }}
                >
                    Guests can join this live appointment by using the
                    link or by entering their email below.
                </DialogContentText>
                <DialogContentText textAlign="center" id="link">
                    <a
                        href="https://telesmarthealth.com/join/HSL8"
                        style={{
                            color: "#0EAAFD",
                            fontSize: 20,
                        }}
                    >
                        https://telesmarthealth.com/join/HSL8
                    </a>
                </DialogContentText>
                <Container
                    maxWidth="md"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 1,
                    }}
                >
                    <TextField
                        aligncontent="center"
                        sx={{
                            width: 400,
                            height: 48,
                            bgcolor: "white",
                            caretColor: "black",
                            WebkitTextFillColor: "black",
                            textAlign: "center",
                            hover: "transparent",
                            borderRadius: 1,
                            input: {
                                fontSize: 18,
                                fontWeight: 700,
                                pt: 1.5,
                            },
                        }}
                        autoFocus
                        margin="dense"
                        id="filled-basic"
                        label=""
                        type="email"
                        variant="filled"
                        onChange={handleEmailChange}
                        InputProps={{
                            disableUnderline: true,
                        }}
                    />
                    <Button
                        sx={{
                            width: 100,
                            maxWidth: "100%",
                            height: 48,
                            marginTop: 1,
                            color: "#000000",
                            bgcolor: "#31D1C5",
                            textAlign: "left",
                            fontSize: 18,
                            fontWeight: 700,
                            marginLeft: -1,
                        }}
                        autoFocus
                        margin="dense"
                        id="filled-basic"
                        label="Email Address"
                        type="email"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Invite
                    </Button>
                </Container>
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    )
}

export default InviteModal