import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    temperature: 0,
    weight: 0,
    bp: 0,
    bg: 0,
    oxygen: 0,
	heartRate: 0,
}

export const measurementSlice = createSlice({
	name: "measurement",
	initialState: initialState,
	reducers: {
		setTemperature: (state, action) => {
			state.temperature = action.payload;
		},
		setWeight: (state, action) => {
			state.weight = action.payload;
		},
		setBp: (state, action) => {
			state.bp = action.payload;
		},
		setBg: (state, action) => {
			state.bg = action.payload;
		},
		setOxygen: (state, action) => {
			state.oxygen = action.payload;
		},
		setHeartRate: (state, action) => {
			state.heartRate = action.payload
		},
		resetMeasurement(state, action) {
			return initialState
		}
	},
});

// Action creators are generated for each case reducer function
export const {
	setTemperature,
	setWeight,
	setBp,
	setBg,
	setOxygen,
	setHeartRate,
	resetMeasurement
} = measurementSlice.actions;

export default measurementSlice.reducer;
