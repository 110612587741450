import React from 'react';
import Button from "@mui/material/Button";
import GroupAddIcon from "@mui/icons-material/GroupAdd";


const InviteButton = ({handleClickOpen}) => {
    return (
        <Button
            variant="contained"
            sx={{
                width: 100,
                height: 48,
                mr: 2,
                fontSize: 18,
                fontWeight: 700,
            }}
            onClick={handleClickOpen}
        >
            <GroupAddIcon sx={{ fontSize: 26, mr: 1 }}></GroupAddIcon>
            Invite
        </Button>
    )
}

export default InviteButton