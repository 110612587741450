import React from 'react';
import Button from "@mui/material/Button";
import MicNoneIcon from "@mui/icons-material/MicNone";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";

const MicButton = ({micToggle, handleMicToggle}) => {
    return (
        <Button
            variant="contained"
            color="toggle"
            sx={{
                width: 48,
                height: 48,
                ml: 2,
                "&:hover": { bgcolor: "#2C373C" },
            }}
            onClick={handleMicToggle}
        >
            {micToggle ? (
                <MicNoneIcon color="action" sx={{ fontSize: 35 }} />
            ) : (
                <MicOffOutlinedIcon
                    color="action"
                    sx={{ fontSize: 35 }}
                />
            )}
        </Button>
    )
}

export default MicButton;