import { configureStore } from "@reduxjs/toolkit";
import measurementReducer from "../features/measurement/measurementSlice";
import roomReducer from "../features/room/roomSlice"

export default configureStore({
	reducer: {
		measurement: measurementReducer,
		room: roomReducer,
	},
});

// https://redux.js.org/tutorials/essentials/part-1-overview-concepts
