import React from 'react';
import Button from "@mui/material/Button";

const LeaveButton = ({handleLogout}) => {
    return (
        <Button
            variant="contained"
            color="error"
            sx={{ width: 124, fontSize: 18, height: 48 }}
            onClick={handleLogout}
        >
            Leave
        </Button>
    )
}

export default LeaveButton;