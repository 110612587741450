import React from 'react';
import Button from "@mui/material/Button";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";

const VideoButton = ({videoToggle, handleVideoToggle}) => {
    return (
        <Button
            variant="contained"
            color="toggle"
            sx={{
                width: 48,
                height: 48,
                ml: 2,
                "&:hover": { bgcolor: "#2C373C" },
            }}
            onClick={handleVideoToggle}
        >
            {videoToggle ? (
                <VideocamOutlinedIcon color="action" fontSize="large" />
            ) : (
                <VideocamOffOutlinedIcon
                    color="action"
                    fontSize="large"
                />
            )}
        </Button>
    )
}

export default VideoButton;