import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "@fontsource/quicksand";

// The custom theme for telesmart
let theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 1000,
			md: 1366,
			lg: 1536,
			xl: 1800,
		}
	},
	palette: {
		mode: "dark",
		primary: {
			main: "#31D1C5",
		},
		secondary: {
			main: "#1E292F",
			contrastText: "#31D1C5",
		},
		toggle: {
			main: "#384247",
		},
		error: {
			main: "#E2292A",
		},
		background: {
			default: "#121E24",
		},
		text: {
			primary: "#FFFFFF",
			secondary: "#31D1C5",
		},
	},
	typography: {
		button: {
			textTransform: "none",
			fontWeight: 500,
			fontSize: 20,
			fontFamily: "Quicksand",
			borderRadius: "6px",
		},
		fontFamily: "Quicksand",
	},
	components: {
		MuiContainer: {
			styleOverrides: {
				root: {
					display: "flex",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					borderColor: "#31D1C5",
					underline: {
						color: "#31D1C5",
					},
					disabled: {},
					"& .MuiInputLabel-root": {
						right: 0,
						textAlign: "center",
					},
					"& .MuiInputLabel-shrink": {
						display: "none", //if you want to hide it completly
					},
					"& .MuiOutlinedInput-root.Mui-focused": {
						"& legend ": {
							display: "none",
						},
					},
					"& .MuiInput-underline:before": {
						borderBottomColor: "#31D1C5",
					},
					"& .MuiInput-underline:hover": {
						borderBottomColor: "#31D1C5",
					},
				},
			},
		},
	},
});

theme = responsiveFontSizes(theme);

export default theme;
