import React from "react";
import styles from "../app.module.css";
import Container from "@mui/material/Container";
import Logo from "../assets/telesmart-logo.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

const Lobby = ({ username, handleUsernameChange, handleSubmit, isLoading }) => {
	
	return (
		<Container maxWidth="xl" className={styles.container}>
			<Container
				sx={{
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<img src={Logo} className={styles.logo} alt="TeleSMART Health" />
				<form onSubmit={handleSubmit} className={styles.form}>
					<TextField
						required
						InputLabelProps={{ required: false }}
						variant="standard"
						label="enter username"
						value={username}
						onChange={handleUsernameChange}
						sx={{
							paddingBottom: 4,
							input: { textAlign: "center", fontSize: 22 },
							width: 460,
							label: { fontSize: 20 },
						}}
					/>
					<Button
						variant="contained"
						type="submit"
						color="secondary"
						sx={{
							":hover": { bgcolor: "#1E292FA9" },
							px: 5,
							py: 1.2,
						}}
					>
						{ isLoading 
							?  <CircularProgress size={24} />
							: <Typography variant="h6">Join Appointment</Typography>
						}
						
					</Button>
				</form>
			</Container>
		</Container>
	);
};

export default Lobby;
