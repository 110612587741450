import React from "react";
import { Box } from "@mui/system";
import styles from "../../app.module.css";
import Logo from "../../assets/telesmart-logo-long.png";
const Header = () => {
	return (
		<Box className={styles.header}>
			<img
				src={Logo}
				className={styles.headerLogo}
				alt="TeleSmart Health"
			/>
		</Box>
	);
};
export default Header;
