import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { SyncClient } from "twilio-sync";
import {useSelector, useDispatch} from 'react-redux'
import Grid from '@mui/material/Grid';
import styles from "../../app.module.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Participant from "../../components/Participant";
import MeasurementSidebar from '../../components/MeasurementSidebar'
import {
	setTemperature,
	setWeight,
	setBp,
	setBg,
	setOxygen,
	setHeartRate,
} from "../measurement/measurementSlice"

const Room = ({room, leaveRoom}) => {
	const roomName = useSelector((state) => state.room.roomName)
	const accessToken = useSelector((state) => state.room.accessToken)
	
	const dispatch = useDispatch()

	const [participants, setParticipants] = useState([]);
	const [hasParticipants, setHasParticipants] = useState(false)
	// const [mainParticipant, setMainParticipant] = useState([])

	initializeSyncClient(accessToken);

	function updateSensor({device, value}) {
		switch (device) {
			case "ihealth_stethescope": {
				break;
			}
			case "ihealth_otoscope": {
				break;
			}
			case "ihealth_temperature": {
				dispatch(setTemperature(value))
				break;
			}
			case "ihealth_weight": {
				dispatch(setWeight(value))
				break;
			}
			case "ihealth_blood_pressure": {
				dispatch(setBp(value))
				break;
			}
			case "ihealth_blood_glucose": {
				dispatch(setBg(value))
				break;
			}
			case "ihealth_oxygen_saturation": {
				dispatch(setOxygen(value))
				break;
			}
			case "ihealth_heart_rate": {
				dispatch(setHeartRate(value))
				break;
			}
			default: {
				console.log('device not defined')
			}
		}
	}

	function initializeSyncClient(tokenResponse) {
		if (accessToken !== '') {
			const syncClient = new SyncClient(tokenResponse);

			syncClient.list(roomName).then(function (list) {
				list.getItems().then(function (pages) {
					console.log('in getItems')
					for (const page of pages.items) {
						console.log('in for loop')
						const data = page;
						console.log('get items', data);
					}
				});

				list.on("itemAdded", function (pages) {
					const data = pages.item.data;
					console.log('item added', data);
					updateSensor(data)
				});
			});
		}
	}

	useEffect(() => {
		console.log('participants', participants)
		if (participants.length > 0) {
			setHasParticipants(true)
		} else {
			setHasParticipants(false)
		}
	}, [participants])

	useEffect(() => {
		console.log(room)
		const participantConnected = (participant) => {
			console.log('participant connected', participant)
			setParticipants((prevParticipants) => [
				...prevParticipants,
				participant,
			]);
		};
	
		const participantDisconnected = (participant) => {
			setParticipants((prevParticipants) =>
				prevParticipants.filter((p) => p.sid !== participant.sid)
			);
		};
		
		room.on("participantConnected", participantConnected);
		room.on("participantDisconnected", participantDisconnected);
		room.participants.forEach(participantConnected);

		window.addEventListener('beforeunload', () => room.disconnect());

		return () => {
			room.disconnect()
			leaveRoom()
		};
	}, [roomName, accessToken, room, leaveRoom]);

	// const getCols = 12 / (participants.length + 1)

	// const getLg = participants.length > 1 ? 4 : 5

	const remoteParticipants = participants.map((participant) => (
		<Participant key={participant.sid} participant={participant} localParticipant="false" />
	));

	return (
		<Box className={styles.room}>
			{room != null && (
				<Box className={styles.roomContainer}>
					<Header />
					<Grid 
						container 
						sx={{flexGrow: 1}}
						justifyContent="space-between"
						alignItems="center"
					>
						{hasParticipants 
							?	<Grid 
									item
									container
									sm
									spacing={1}
									justifyContent="center"
									alignItems="center"
									direction="row"
									sx={{flexGrow: 1, pl: '20px', height: '80vh'}}
								>
									<Participant
										key={room.localParticipant.sid}
										participant={room.localParticipant}
										localParticipant="true"
									/>
									{remoteParticipants}
								</Grid>
							:	<Grid 
									item
									container
									sm
									spacing={1}
									justifyContent="center"
									alignItems="center"
									direction="row"
									sx={{flexGrow: 1, pl: '20px'}}
								>
									<Participant
										key={room.localParticipant.sid}
										participant={room.localParticipant}
										localParticipant="true"
										sx={{margin: '10px'}}
									/>
								</Grid>
						}
						<Grid item xs={2} sx={{flexGrow: 0, mr: '20px', pl: 2}}>
							<MeasurementSidebar />
						</Grid>
					</Grid>
					<Footer
						room={room}
					/>
				</Box>
			)}
		</Box>
	);
};

export default Room;
