import React, { useState } from "react";
import {useDispatch} from 'react-redux'
import { Box } from "@mui/system";
import styles from "../../app.module.css";
import {
	resetRoom
} from '../../features/room/roomSlice'
import {
	resetMeasurement
} from '../../features/measurement/measurementSlice'
import InviteButton from "./buttons/InviteButton";
import LeaveButton from "./buttons/LeaveButton";
import MicButton from "./buttons/MicButton";
import VideoButton from "./buttons/VideoButton";
import InviteModal from "./InviteModal";

const Footer = ({room}) => {

	const [micToggle, setMicToggle] = useState(true);
	const [videoToggle, setVideoToggle] = useState(true);
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch()

	const handleMicToggle = () => {
		room.localParticipant.audioTracks.forEach((trackPublication) => {
			if (micToggle === true) {
				trackPublication.track.disable();
			} else {
				trackPublication.track.enable();
			}
		});

		setMicToggle((previousMic) => {
			return !previousMic;
		});
	};

	const handleVideoToggle = () => {
		room.localParticipant.videoTracks.forEach((trackPublication) => {
			if (videoToggle === true) {
				trackPublication.track.disable();
			} else {
				trackPublication.track.enable();
			}
		});

		setVideoToggle((previousVideo) => {
			return !previousVideo;
		});
	};

	const handleLogout = () => {
		room.disconnect();
		dispatch(resetRoom())
		dispatch(resetMeasurement())
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box className={styles.footer}>
			<Box className={styles.leftButtonsContainer}>
				<MicButton 
					micToggle={micToggle} 
					handleMicToggle={handleMicToggle} 
				/>
				<VideoButton 
					videoToggle={videoToggle} 
					handleVideoToggle={handleVideoToggle} 
				/>
			</Box>

			<LeaveButton handleLogout={handleLogout} />

			<Box className={styles.rightButtonsContainer}>
				<InviteButton handleClickOpen={handleClickOpen} />
				<InviteModal open={open} handleClose={handleClose} />
			</Box>
		</Box>
	);
};
export default Footer;
